import { getDefaultLimitRepresentation } from "common/utils/limitRepresentation";
import { CleaningLimitPolicyResidueType } from "./enums";
import { PolicyConfigUI } from "./types";
import { DEFAULT_UNITS } from "./utils/default-units";

export const defaultCleaningPolicy: PolicyConfigUI = {
  useDosage: false,
  useToxicity: false,
  useProductLimit: false,
  useLd50: false,
  useARL: false,
  arl: { value: 10, unit: DEFAULT_UNITS.L5 },
  residueLimit: { value: 10, unit: DEFAULT_UNITS.L3 },
  useResidueLimit: false,
  ignoreCombo: false,
  useGlobalRecoveryPercentage: false,
  globalRecoveryPercentage: 100,
  residueType: CleaningLimitPolicyResidueType.Chemical,
  useSAR: false,
  limitRepresentation: getDefaultLimitRepresentation(),
  useFirstAvailableCriteria: false,
  intermediateArl: 0,
  activesArl: { value: 10, unit: DEFAULT_UNITS.L5 },
  useSampleLimit: false,
  swabSampleLimit: 10,
  rinseSampleLimit: 10,
  useFixedSampleLimits: false,
  fixedSwabLimit: 10,
  fixedRinseLimit: 10
};

export const ROOM_GRADE_ID = "roomGradeId";

// Keys of this constant should be the same as svg we are adding for the shape
// SVG image will be loaded on the basis of this.
export const GEOMETRY_OPTIONS = {
  cylinder: {
    formula: "2*π*r*h+2*π*(r**2)",
    visualFormula: "2π * r * h + 2 * πr^2",
    label: "Cylindrical",
    inputs: {
      r: "Radius (r)",
      h: "Height (h)"
    }
  },
  circle: {
    formula: "π*r**2",
    visualFormula: "πr^2",
    label: "Circle",
    inputs: {
      r: "Radius (r)"
    }
  },
  circular_ring: {
    formula: "π*(r**2)-π*(R1**2)",
    visualFormula: "πr^2 - πR1^2",
    label: "Circular ring",
    inputs: {
      r: "Radius (r)",
      R1: "Inner radius (R1)"
    }
  },
  cube: {
    formula: "6*(l**2)",
    visualFormula: "6*L^2",
    label: "Cube",
    inputs: {
      l: "Length (L)"
    }
  },
  cone: {
    formula: "π*r*(r+Math.sqrt(h**2+r**2))",
    visualFormula: "πr (r+ sqrt(h^2 + r^2))",
    label: "Cone",
    inputs: {
      r: "Radius (r)",
      h: "Height (h)"
    }
  },
  square: {
    formula: "l**2",
    visualFormula: "l^2",
    label: "Square",
    inputs: {
      l: "Length (L)"
    }
  },
  rectangle: {
    formula: "l*h",
    visualFormula: "l*h",
    label: "Rectangle",
    inputs: {
      l: "Length (l)",
      h: "Height (h)"
    }
  },
  triangle: {
    formula: "(1/2)*b*h",
    visualFormula: "1/2 * b * h",
    label: "Triangle",
    inputs: {
      b: "base (b)",
      h: "Height (h)"
    }
  },
  cuboid_lateral: {
    formula: "2 * (l + b) * h",
    visualFormula: "2 * (l + b) * h",
    label: "Cuboid Lateral",
    inputs: {
      b: "Base (b)",
      h: "Height (h)",
      l: "Length (l)"
    }
  },
  cylinder_lateral: {
    formula: "2 * π * r * h",
    visualFormula: "2π * r * h",
    label: "Cylinder lateral surface",
    inputs: {
      r: "Radius (r)",
      h: "Height (h)"
    }
  },
  ellipse: {
    formula: "π*R1*R2 ",
    visualFormula: "πR1*R2 ",
    label: "Ellipse",
    inputs: {
      R1: "Radius1 (R1)",
      R2: "Radius2 (R2)"
    }
  },
  frustrum_curved: {
    formula: "π * (R1+R2) * S",
    visualFormula: "π * (R1+R2) * S",
    label: "Frustum curved surface",
    inputs: {
      R1: "Radius1 (R1)",
      R2: "Radius2 (R2)",
      S: "Slant Height (s)"
    }
  },
  hemisphere: {
    formula: "3 * π * r**2 ",
    visualFormula: "3 * πr^2 ",
    label: "Hemisphere",
    inputs: {
      r: "Radius2 (r)"
    }
  },
  perforated_turret: {
    formula: "π * r**2 - π * R1**2 - n*(π*R2**2)",
    visualFormula: "πr^2 - πR1^2 - n*(πR2^2)",
    label: "Perforated turret",
    inputs: {
      r: "Outer Radius (r)",
      R1: "Inner Radius (R1)",
      R2: "Radius of holes (R2)",
      n: "Number of holes (n)"
    }
  },
  rectangular_prism: {
    formula: "2 * (B*H + H*L + L*B)",
    visualFormula: "2 (B*H + H*L + L*B)",
    label: "Rectangular prism",
    inputs: {
      B: "Base (B)",
      H: "Height (H)",
      L: "Length (L)"
    }
  },
  trapezium_prism_lateral: {
    formula: "(L1 + B1) * H1 + (L2 + B2) * H2",
    visualFormula: "(L1 + B1) * H1 + (L2 + B2) * H2",
    label: "Trapezium prism lateral surface",
    inputs: {
      B1: "Base (B1)",
      H1: "Height (H1)",
      L1: "Length (L1)",
      B2: "Base (B2)",
      H2: "Height (H2)",
      L2: "Length (L2)"
    }
  },
  trapezium: {
    formula: "1/2 * (L + B) * H",
    visualFormula: "1/2 * (L + B) * H",
    label: "Trapezium",
    inputs: {
      B: "Base (B)",
      H: "Height (H)",
      L: "Length (L)"
    }
  },
  sphere: {
    formula: "4*π*r**2",
    visualFormula: "4 * πr^2",
    label: "Sphere",
    inputs: {
      r: "Radius (r)"
    }
  },
  curved_surface_area_of_a_hemisphere: {
    formula: "2*π*r**2",
    visualFormula: "2 * πr^2",
    label: "Curved surface area of a hemisphere",
    inputs: {
      r: "Radius (r)"
    }
  },
  lateral_surface_area_of_a_frustum_of_a_cone: {
    formula: "π * (r1 + r2) * Math.sqrt((r1 - r2)**2 + H**2)",
    visualFormula: "π * (r1 + r2) * √((r1 - r2)^2 + H^2)",
    label: "Lateral surface area of a frustum of a cone",
    inputs: {
      r1: "Radius (r1)",
      r2: "Radius (r2)",
      H: "Height (H)"
    }
  }
};
